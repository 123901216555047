// REACT, STYLE, STORIES & COMPONENT
import React, {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import styles from './QuestionAiFeedback.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useParams } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Button, InfoCard, PillGroup, ResizableTextArea, SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { debounce } from 'utils/delay';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  rows: 12,
  buttonHeight: 40,
  xsSpacing: 16,
};

const PILLS = {
  MY_ANSWER: 'my_answer',
  AI_SUGGESTION: 'ai_suggestion',
};

// COMPONENT: QuestionAiFeedback
const QuestionAiFeedback = (props) => {
  // PROPS
  const {
    question,
    onChange,
    onSubmit,
  } = props;

  // SPECIAL HOOKS
  const params = useParams();
  const translate = useTranslate();
  const bp = useBreakpoint();

  const textAreasRef = useRef(null);
  const leftTextareaRef = useRef(null);
  const rightTextareaRef = useRef(null);
  const [ resizedHeight, setResizedHeight ] = useState();
  const resize = debounce((clientHeight) => {
    const left = leftTextareaRef.current;
    const right = rightTextareaRef.current;
    if (!left || !right) {
      return;
    }

    if (left.clientHeight === right.clientHeight) {
      return;
    }

    setResizedHeight(clientHeight - Config.buttonHeight - Config.xsSpacing * 2);
  }, 300);

  const resizeObserver = new ResizeObserver((entries) => {
    resize(entries[0]?.target.clientHeight);
  });

  useLayoutEffect(() => {
    resizeObserver.observe(textAreasRef.current);

    return () => {
      resizeObserver.unobserve(textAreasRef.current);
    };
  }, [ resizeObserver ]);

  const [ pillItemId, setPillItemId ] = useState(PILLS.MY_ANSWER);

  const [ answer, setAnswer ] = useState(question?.value || '');
  const [ feedback, setFeedback ] = useState('');
  const [ analysis, setAnalysis ] = useState('');
  const [ feedbackAndAnalysisError, setFeedbackAndAnalysisError ] = useState('');
  const [ feedbackAndAnalysisProcessing, setFeedbackAndAnalysisProcessing ] = useState(false);
  const fetchFeedback = useCallback(() => {
    setFeedbackAndAnalysisProcessing(true);

    apiNext.post(`/core/assessments/${params.customAssessmentId}/0/answers/${question.id}/feedback`, {
      answer,
    })
    .then((response) => {
      const { analysis: analysisInternal, aiVersion } = response;
      setAnalysis(analysisInternal);
      setFeedback(aiVersion);
      setPillItemId(PILLS.AI_SUGGESTION);
    })
    .catch((error) => {
      console.error(error.message);
      setFeedbackAndAnalysisError(error.message);
    })
    .finally(() => {
      setFeedbackAndAnalysisProcessing(false);
    });
  }, [
    params.customAssessmentId,
    answer,
    question,
  ]);

  useEffect(() => {
    if (feedback || feedbackAndAnalysisProcessing || feedbackAndAnalysisError) {
      return;
    }

    fetchFeedback();
  }, [
    params.customAssessmentId,
    feedback,
    feedbackAndAnalysisProcessing,
    feedbackAndAnalysisError,
    fetchFeedback,
  ]);

  const showReevaluateButton = question.ai_assistance_allow_reevaluation
    && answer && answer !== question.value;


  const MyAnswer = (
    <div className={styles.textarea}>
      <div ref={leftTextareaRef}>
        <ResizableTextArea
          label={translate('your_answer_lbl')}
          value={answer}
          minRows={Config.rows}
          maxRows={Config.rows}
          disabled={feedbackAndAnalysisProcessing}
          resizable='manual'
          heightOverride={resizedHeight}
          onChange={(value) => {
            setAnswer(value);
            onChange?.();
          }}
          onReevaluate={showReevaluateButton
            ? fetchFeedback
            : undefined}
        />
      </div>

      { question.maxAnswerLength && (
        <div className={styles.hint}>
          <span className={classNames({ [styles.error]: answer?.length > question.maxAnswerLength })}>
            { answer?.length }
          </span>
          { ` / ${question.maxAnswerLength}` }
        </div>
      ) }

      <Button
        size='M'
        disabled={feedbackAndAnalysisProcessing || answer?.length > question.maxAnswerLength}
        onClick={() => onSubmit?.(answer)}
      >
        { translate('free_text_ai_assistance_continue') }
      </Button>
    </div>
  );

  const AiSuggestion = (
    <div className={styles.textarea}>
      { feedbackAndAnalysisProcessing && (
        <div className={styles.loading}>
          <SkeletonFlexible noHeader repeat={5} />
        </div>
      ) }

      { !feedbackAndAnalysisProcessing && (
        <>
          <div ref={rightTextareaRef}>
            <ResizableTextArea
              label={translate('ai_suggestion')}
              looks='evo'
              value={feedback}
              minRows={Config.rows}
              maxRows={Config.rows}
              disabled={feedbackAndAnalysisError}
              resizable='manual'
              heightOverride={resizedHeight}
              onChange={(value) => {
                setFeedback(value);
                onChange?.();
              }}
            />
          </div>

          { question.maxAnswerLength && (
            <div className={styles.hint}>
              <span className={classNames({ [styles.error]: feedback?.length > question.maxAnswerLength })}>
                { feedback?.length }
              </span>
              { ` / ${question.maxAnswerLength}` }
            </div>
          ) }

          <Button
            size='M'
            looks='evo'
            disabled={feedbackAndAnalysisError || feedback?.length > question.maxAnswerLength}
            onClick={() => onSubmit?.(feedback)}
          >
            { translate('free_text_ai_assistance_continue') }
          </Button>
        </>
      ) }
    </div>
  );

  if (!question) {
    return null;
  }

  // RENDER: QuestionAiFeedback
  return (
    <div className={styles.questionAiFeedback}>
      <div className={styles.gridContent}>
        <span className='bluTypeS'>{ question.question }</span>

        <div className={styles.infoCardWrapper}>
          <InfoCard
            looks='evo'
            content={analysis
              || (feedbackAndAnalysisError && <span className='error'>{ feedbackAndAnalysisError }</span>)}
            loading={feedbackAndAnalysisProcessing}
          />
        </div>

        { bp.isXs && (
          <>
            <PillGroup
              pillItems={[
                {
                  id: PILLS.MY_ANSWER,
                  label: translate('my_answer_lbl'),
                },
                {
                  id: PILLS.AI_SUGGESTION,
                  label: translate('ai_suggestion'),
                  loading: feedbackAndAnalysisProcessing,
                },
              ]}
              activeIndex={pillItemId === PILLS.MY_ANSWER ? 0 : 1}
              onPillClick={(pillItem) => setPillItemId(pillItem.id)}
            />

            { pillItemId === PILLS.MY_ANSWER ? MyAnswer : AiSuggestion }
          </>
        ) }

        { !bp.isXs && (
          <div ref={textAreasRef} className={styles.textareas}>
            { MyAnswer }
            { AiSuggestion }
          </div>
        ) }

      </div>
    </div>
  );
};

export default QuestionAiFeedback;
