// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './ExternalAssessmentWrapper.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

// OTHER COMPONENTS
import { Button, Loading } from 'ui/basic';
import CustomAssessmentPage from 'features/+core/pages/Assessment/CustomAssessmentPage';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { COMPANY_ID } from 'utils/configuration';

// STORE NEXT
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions';
import { getCompanySettings } from 'store/selectors/settings';
import { getConfigurationExtended } from 'store/actions/configuration';

// CONFIG & DATA
// const Config = {};


// COMPONENT: ExternalAssessmentWrapper
const ExternalAssessmentWrapper = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const translate = useTranslate();

  const { shareToken, customAssessmentId } = params;

  const companySettings = useSelector(getCompanySettings);

  const [ errorMessageBody, setErrorMessageBody ] = useState('');

  const clearShareTokenFromLocalStorage = useCallback(() => {
    if (localStorage.getItem(`shareToken${shareToken}`)) {
      localStorage.removeItem(`shareToken${shareToken}`);
    }
  }, [ shareToken ]);

  // SHARE TOKEN INFO: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDER
  const [ shareTokenInfo, setShareTokenInfo ] = useState();
  const [ shareTokenInfoFetchProcessing, setShareTokenInfoFetchProcessing ] = useState(false);
  useEffect(() => {
    if (shareTokenInfo) {
      return;
    }

    setShareTokenInfoFetchProcessing(true);
    apiNext.get(`/core/user/profile/share/${shareToken}`)
    .then((response) => {
      setShareTokenInfo(response);
      dispatch(getConfigurationExtended());
    })
    .catch((error) => {
      clearShareTokenFromLocalStorage();

      setErrorMessageBody(error.responseContent?.error?.errorMessage ?? error.message);
      console.error(error.message);
    })
    .finally(() => {
      setShareTokenInfoFetchProcessing(false);
    });
  }, [
    translate,
    shareToken,
    shareTokenInfo,
    clearShareTokenFromLocalStorage,
  ]);

  const [ loginProcessing, setLoginProcessing ] = useState(false);
  const login = useCallback(() => {
    setLoginProcessing(true);
    apiNext.post('/core/user/login', {
      shareToken,
      company: COMPANY_ID,
    })
    .then((response = {}) => {
      const { token } = response;
      if (token) {
        localStorage.setItem(`shareToken${shareToken}`, token);
      }

      dispatch(actions.getCompanySettings(COMPANY_ID));
      dispatch(getConfigurationExtended());
    })
    .catch((error) => {
      clearShareTokenFromLocalStorage();

      setErrorMessageBody(error.responseContent?.error?.errorMessage ?? error.message);
      console.error(error.message);
    })
    .finally(() => {
      setLoginProcessing(false);
    });
  }, [
    dispatch,
    shareToken,
    clearShareTokenFromLocalStorage,
  ]);

  useEffect(() => {
    if (shareToken && localStorage.getItem(`shareToken${shareToken}`)) {
      return;
    }

    login();
  }, [
    login,
    shareToken,
  ]);

  const userHasBqAccount = companySettings.openRegistrationDomains?.includes(shareTokenInfo?.peer?.split('@')?.[1]);

  const handleErrorButtonClick = () => {
    if (userHasBqAccount) {
      history.push('/');
    } else {
      window.open('https://bluquist.com/', '_self');
    }
  };

  const [ reLoggedIn, setReLoggedIn ] = useState(false);
  const handleError = useCallback((error = {}) => {
    if (reLoggedIn) {
      clearShareTokenFromLocalStorage();
      setErrorMessageBody(error.errorMessage || translate('assmnt_config_error'));
      return;
    }

    clearShareTokenFromLocalStorage();
    setReLoggedIn(true);
    login();
  }, [ translate, clearShareTokenFromLocalStorage, reLoggedIn, login ]);

  if (loginProcessing || shareTokenInfoFetchProcessing) {
    return <Loading visible />;
  }

  if (errorMessageBody) {
    let title = 'generic_error_title';
    let body = errorMessageBody;

    if (errorMessageBody === 'Invalid share token') {
      title = 'unavailable_error_title';
      body = 'unavailable_error_body';
    }

    return (
      <div className={styles.externalAssessmentWrapper}>
        <div className={styles.gridContent}>
          <div className='bluTypeM'>
            { translate(title) }
          </div>
          <div className={classNames('bluTypeCopy', 'marginTopS')}>
            { translate(body) }
          </div>

          <Button
            size='L'
            onClick={handleErrorButtonClick}
          >
            { userHasBqAccount
              ? translate('share_profile_link_expired_btn')
              : translate('bq_learn_more') }
          </Button>
        </div>
      </div>
    );
  }

  return (
    <CustomAssessmentPage
      assessmentId={customAssessmentId}
      user={{
        id: shareTokenInfo?.peer,
        hasBqAccount: userHasBqAccount,
      }}
      shareToken={shareToken}
      peerFor={getFullName(shareTokenInfo?.forUser)}
      onError={(error = {}) => handleError(error)}
    />
  );
};

export default ExternalAssessmentWrapper;
