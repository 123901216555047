import React, { useState } from 'react';
import styles from './RmpAssessmentEnd.module.scss';

// STORE

// UTILS
import { useTranslate } from 'utils/translator';

// OTHER COMPONENTS
import { Button } from 'ui/basic';


const RmpAssessmentEnd = (props) => {
  const {
    finishable = false,
    handleEnd = () => {},
    errorEnd,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ submitButtonDisabled, setSubmitButtonDisabled ] = useState(false);

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);


  return (
    <div
      className={styles.assessmentEnd}
      data-test='AssessmentEnd'
    >
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>

          <div className={styles.sTitle}>
            { translate('rmp_final_page_title') }
          </div>

          <div className={styles.copy}>
            { translate('rmp_final_page_copy') }
          </div>

          <Button
            size='M'
            looks='secondary'
            disabled={submitButtonDisabled && !finishable}
            onClick={() => {
              setSubmitButtonDisabled(true);
              handleEnd();
            }}
          >
            { translate('send_lbl') }
          </Button>
        </>
      ) }
    </div>
  );
};

export default RmpAssessmentEnd;
