// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './BodyBatteryStatistics.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import dayjs from 'dayjs';

// OTHER COMPONENTS
import { Card, Scrollable, SkeletonFlexible } from 'ui/basic';
import { GarminIcon } from 'features/+garmin/ui/basic/GarminIcon';

// UTILS
import { useTranslate } from 'utils/translator';
import { sortAlphabetically } from 'utils/strings';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  statisticsItemsPerPage: 5,
};

// COMPONENT: BodyBatteryStatistics
const BodyBatteryStatistics = (props) => {
  // PROPS
  const { history } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ mappedHistory, setMappedHistory ] = useState();
  useEffect(() => {
    if (history && !mappedHistory) {
      const bodyBatteryHistory = history.bodyBattery;
      const sleepHistory = history.sleep;
      const stressHistory = history.stress;

      const internalMappedHistory = {};

      // body battery mapping
      bodyBatteryHistory.forEach((historyItem) => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const { timestamp } = historyItem.score;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = { bodyBatteryClass: historyItem.score.class };
          return;
        }

        if (!internalMappedHistory[timestamp].bodyBatteryClass) {
          internalMappedHistory[timestamp].bodyBatteryClass = historyItem.score.class;
        }
      });

      // sleep mapping
      sleepHistory.forEach((historyItem) => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const { timestamp } = historyItem.score;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = { sleepClass: historyItem.score.class };
          return;
        }

        if (!internalMappedHistory[timestamp].sleepClass) {
          internalMappedHistory[timestamp].sleepClass = historyItem.score.class;
        }
      });

      // stress mapping
      stressHistory.forEach((historyItem) => {
        if (!historyItem || !historyItem.score.timestamp) {
          return;
        }

        const { timestamp } = historyItem.score;

        if (!internalMappedHistory[timestamp]) {
          internalMappedHistory[timestamp] = { stressClass: historyItem.score.class };
          return;
        }

        if (!internalMappedHistory[timestamp].stressClass) {
          internalMappedHistory[timestamp].stressClass = historyItem.score.class;
        }
      });

      setMappedHistory(internalMappedHistory);
    }
  }, [ history, mappedHistory ]);

  if (!history || !mappedHistory) {
    return <SkeletonFlexible />;
  }

  // RENDER: BodyBatteryStatistics
  return (
    <div className={classNames(styles.bodyBatteryStatistics)}>
      <Card>
        <span className='bluTypeXxs'>
          { translate('body_battery_statistics_title') }
        </span>
        <div className={styles.table}>
          { /* TABLE HEADER */ }
          <div className={styles.tHeader}>
            <div className={styles.tHeaderItem}>{ translate('date') }</div>
            <div className={styles.tHeaderItem}>{ translate('body_battery') }</div>
            <div className={styles.tHeaderItem}>{ translate('sleep_quality') }</div>
            <div className={styles.tHeaderItem}>{ translate('stress_tag') }</div>
          </div>

          <Scrollable
            pagination
            drag
            showPaginationNumbers
            showPagerButtons
            pageMargin={16}
          >
            { new Array(Math.ceil(Object.keys(mappedHistory).length / Config.statisticsItemsPerPage))
            .fill(1)
            .map((_, index) => (
              <div className={styles.tRows} key={index}>
                { Object.keys(mappedHistory)
                .sort((a, b) => sortAlphabetically(a, b, true)) // sort the dates descending
                .slice(index * Config.statisticsItemsPerPage, (index * Config.statisticsItemsPerPage) + 5)
                .map((date) => {
                  const formattedDate = dayjs.utc(date * 1000).format('L');
                  return (
                    <div className={styles.tRow} key={date}>
                      <span className='bluTypeCopy'>
                        { formattedDate }
                      </span>
                      <GarminIcon
                        looks='body-battery'
                        size='S'
                        scoreClass={mappedHistory[date].bodyBatteryClass}
                      />
                      <GarminIcon
                        looks='sleep'
                        size='S'
                        scoreClass={mappedHistory[date].sleepClass}
                      />
                      <GarminIcon
                        looks='stress'
                        size='S'
                        scoreClass={mappedHistory[date].stressClass}
                      />
                    </div>
                  );
                }) }
              </div>
            )) }
          </Scrollable>
        </div>
      </Card>
    </div>
  );
};

export default BodyBatteryStatistics;
