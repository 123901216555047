// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './QuestionStartDate.module.scss';

// 3RD PARTY
import dayjs from 'dayjs';
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import {
  AssessmentRadioButton,
  InputNext,
  InputMasked,
  RadioButton,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';
import { DATE_FORMAT } from 'utils/configuration/const/languages';

// CONFIG & DATA
const Config = {
  options: [
    {
      label: 'cp_start_now_question',
      value: 'now',
    },
    {
      label: 'cp_start_date_question',
      value: 'preferredDate',
    },
    {
      label: 'cp_start_notice_period_question',
      value: 'noticePeriod',
    },
  ],
};


// COMPONENT: QuestionStartDate
const QuestionStartDate = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer,
    onAnswer,
  } = props;

  const translate = useTranslate();
  const dateFormat = dayjs().localeData().longDateFormat('L');

  const [ errorMessage, setErrorMessage ] = useState();
  const [ radioValue, setRadioValue ] = useState();
  const [ dateValue, setDateValue ] = useState(); // Actually the value of input field

  useEffect(() => {
    if (!answer?.value?.radioValue) {
      return;
    }

    setRadioValue(answer.value.radioValue);
    // The start date is stored in DE format, so for backward compatibility
    // we convert the german date to the locale.
    const dateDe = dayjs(answer.value.dateValue, DATE_FORMAT.DE, true);
    setDateValue(dateDe.isValid()
      ? dateDe.format('L')
      : answer.value.dateValue);
  }, [ answer ]);

  const publishNewAnswer = (newRadioValue, newDateValue, newError = '') => {
    const isValid = !newError && (newRadioValue === 'now' || Boolean(newDateValue));
    onAnswer?.({
      value: {
        radioValue: newRadioValue,
        dateValue: newDateValue,
      },
      name: 'startDate',
      isValid,
    });
  };

  const handleRadio = (value) => {
    setRadioValue(value);
    setDateValue();
    setErrorMessage();
    publishNewAnswer(value);
  };

  const handleDateOption = (value) => {
    setDateValue(value);
    const date = dayjs(value, dateFormat, true);
    if (!date.isValid()) {
      const error = translate(
        'share_profile_validto_invalid_format_msg_v2',
        [ '{{format}}', dateFormat ],
      );
      setErrorMessage(error);
      publishNewAnswer(radioValue, value, error);
      return;
    }

    // Date is ok: convert format back to DE
    const valueDe = dayjs(date).locale('de').format('L');
    setErrorMessage();
    publishNewAnswer(radioValue, valueDe);
  };

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: QuestionStartDate
  return (
    <div className={classNames(styles.questionStartDate, styles[`size${capitalise(size)}`])}>

      <div className={classNames(styles.formRow, styles.assessmentRadioButtonGroup)}>
        { Config.options.map((option) => (
          <React.Fragment key={option.value}>
            { size === 'responsive' && (
              <AssessmentRadioButton
                title={translate(option.label)}
                value={option.value}
                checked={radioValue === option.value}
                onDeselect={handleRadio}
                onChange={handleRadio}
              />
            ) }

            { size !== 'responsive' && (
              <RadioButton
                title={translate(option.label)}
                checked={radioValue === option.value}
                value={option.value}
                onChange={handleRadio}
              />
            ) }

            { option.value === 'preferredDate' && radioValue === 'preferredDate' && (
              <div className={styles.inputContainer}>
                <InputMasked
                  size={size}
                  label={translate('cp_start_date_placeholder')}
                  mask={dateFormat.replaceAll(/\w/g, '9')}
                  errorMessage={errorMessage}
                  value={dateValue}
                  onChange={handleDateOption}
                />
              </div>
            ) }

            { option.value === 'noticePeriod' && radioValue === 'noticePeriod' && (
              <div className={styles.inputContainer}>
                <InputNext
                  size={size}
                  label={translate('cp_start_notice_period_placeholder')}
                  value={dateValue}
                  onChange={(value) => {
                    setDateValue(value);
                    publishNewAnswer(radioValue, value);
                  }}
                />
              </div>
            ) }
          </React.Fragment>
        )) }
      </div>

    </div>
  );
};

export default QuestionStartDate;
