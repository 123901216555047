// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useRef, useState } from 'react';
import styles from './InfoCard.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Card, SkeletonFlexible } from 'ui/basic/index';
import { ReactComponent as Assistant } from './icn_bq_asisstant.svg';

// UTILS
import {
  breakpoints, useBreakpoint, useDebounce, useWindowWidth,
} from 'utils/hooks';
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  collapsedContentMaxHeight: 60,
};


// COMPONENT: InfoCard
const InfoCard = (props) => {
  // PROPS
  const {
    title = '',
    content = '',
    looks = 'classic', // classic, evo
    loading = false,
    isExpanded = true,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const { ArrowDown, InfoBlue } = IconsSvg;

  const bp = useBreakpoint();
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 300);

  const hiddenContentRef = useRef();

  const [ expanded, setExpanded ] = useState(false);
  useEffect(() => {
    setExpanded(isExpanded && bp.bpWidth >= breakpoints.M.bpWidth);
  }, [ bp, debouncedWindowWidth, isExpanded ]);

  const [ contentMaxHeight, setContentMaxHeight ] = useState();
  const [ notExpandable, setNotExpandable ] = useState(false);
  useEffect(() => {
    if (!content) {
      return;
    }

    setNotExpandable(false);

    const hiddenContentElem = hiddenContentRef.current || {};
    setContentMaxHeight(expanded ? hiddenContentElem.clientHeight : Config.collapsedContentMaxHeight);

    setTimeout(() => {
      setNotExpandable(hiddenContentElem.clientHeight <= Config.collapsedContentMaxHeight);
    }, 300);
  }, [
    title,
    content,
    expanded,
    debouncedWindowWidth,
  ]);

  // RENDER: InfoCard
  return (
    <div className={classNames(
      styles.infoCard,
      styles[looks],
      {
        [styles.expanded]: expanded,
        [styles.notExpandable]: notExpandable,
      },
    )}
    >
      <Card
        hasPaddingsForModal
        className={styles.infoSubCard}
      >
        <div
          role='presentation'
          className={styles.header}
          onClick={() => setExpanded(!expanded)}
        >
          { looks === 'classic' && (
            <>
              <InfoBlue />
              <span className='bluTypeLabelL'>
                { title }
              </span>
            </>
          ) }

          { looks === 'evo' && (
            <>
              <Assistant />
              <span className='bluTypeLabelL'>bluquist evo AI</span>
            </>
          ) }

          <div className={styles.arrow}>
            <ArrowDown />
          </div>
        </div>

        <div
          className={styles.content}
          style={{
            maxHeight: contentMaxHeight,
          }}
        >
          { loading && (
            <div className='marginTopXs'>
              <SkeletonFlexible
                noHeader
                looks='purple'
                repeat={1}
              />
            </div>
          ) }

          { !loading && (
            <>
              { translate(content) }

              <div
                ref={hiddenContentRef}
                className={styles.hiddenContent}
              >
                { translate(content) }
              </div>
            </>
          ) }
        </div>
      </Card>
    </div>
  );
};

export default InfoCard;
