import React from 'react';
import styles from './DiscAssessmentEnd.module.scss';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router';

// STORE
// import { useDispatch } from 'react-redux';

// UTILS
import { useTranslate } from 'utils/translator';
import { replaceCoachHubTarget } from 'features/+coachHub/utils/localStorage';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// CONFIG & DATA


const DiscAssessmentEnd = (props) => {
  const {
    finishable,
    errorEnd,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  const handleReportButtonClick = () => {
    if (!localStorage.getItem('coachHub')) {
      return;
    }

    replaceCoachHubTarget('profile', '/my-profile');
  };


  return (
    <div
      className={styles.discAssessmentEnd}
      data-test='AssessmentEnd'
    >
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>&#128588;</span>
          <div className={classNames('bluTypeS', 'marginTopXs')}>
            { translate('disc_ass_success_title') }
          </div>

          { finishable === undefined && (
            <div className={classNames('bluTypeCopy', 'marginTopS')}>
              { translate('disc_ass_success_copy') }
            </div>
          ) }

          { finishable === false && (
            <div className={classNames('bluTypeCopy', 'marginTopS')}>
              { translate('disc_ass_result_error') }
            </div>
          ) }

          { finishable && (
            <Button
              size='M'
              looks='secondary'
              onClick={handleReportButtonClick}
            >
              { translate('big5_results_button_showreport') }
            </Button>
          ) }
        </>
      ) }
    </div>
  );
};

export default DiscAssessmentEnd;
