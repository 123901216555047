// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Card.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useBreakpoint, breakpoints } from 'utils/hooks';

// CONFIG & DATA

// COMPONENT: Card
const Card = (props) => {
  // PROPS
  const {
    children,
    className,
    hasFullHeight,
    hasPaddingsSmall,
    hasPaddingsXs,
    hasPaddingsForModal,
    hasNoPaddings,
    hasGrabCursor,
    hasHover, // add hover effect
    hasHoverBlue,
    hasBorderBlue,
    hasBackgroundGrey,
    hasBackgroundBlue,
    hasBackgroundTransparent,
    hasBreakout, // remove responsive paddings based on boolean or if smaller than provided viewport 'M', 'S', 'Xs', ...
    onClick,
  } = props;


  // COMPONENT/UI STATE and REFS

  // SPECIAL HOOKS
  const bp = useBreakpoint();
  let hasBreakoutClass;
  if (typeof hasBreakout === 'boolean') {
    hasBreakoutClass = hasBreakout;
  }
  if (typeof hasBreakout === 'string') {
    const bpTarget = breakpoints[hasBreakout];
    if (!bpTarget) throw Error(`component[Card]: unknown breakpoint ${bpTarget}`);
    hasBreakoutClass = bp.bpWidth <= bpTarget.bpWidth;
  }
  // STORE HOOKS

  // EFFECT HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: Card
  const cardProps = {
    onClick,
  };

  return (
    <div
      className={classnames(`${styles.card}`, className, {
        [styles.hasFullHeight]: hasFullHeight,
        [styles.hasPaddingsSmall]: hasPaddingsSmall,
        [styles.hasPaddingsXs]: hasPaddingsXs,
        [styles.hasPaddingsForModal]: hasPaddingsForModal,
        [styles.hasNoPaddings]: hasNoPaddings,
        [styles.hasGrabCursor]: hasGrabCursor,
        [styles.hasHover]: hasHover,
        [styles.hasHoverBlue]: hasHoverBlue,
        [styles.hasBorderBlue]: hasBorderBlue,
        [styles.hasBackgroundGrey]: hasBackgroundGrey,
        [styles.hasBackgroundBlue]: hasBackgroundBlue,
        [styles.hasBackgroundTransparent]: hasBackgroundTransparent,
        [styles.hasBreakout]: hasBreakoutClass,
      })}
      {...cardProps}
    >
      { children }
    </div>
  );
};


export default Card;
