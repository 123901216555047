import { translate } from 'utils/translator/translator';


export const monthKeys = [
  'cp_dropdown_month_january',
  'cp_dropdown_month_february',
  'cp_dropdown_month_march',
  'cp_dropdown_month_april',
  'cp_dropdown_month_may',
  'cp_dropdown_month_june',
  'cp_dropdown_month_july',
  'cp_dropdown_month_august',
  'cp_dropdown_month_september',
  'cp_dropdown_month_october',
  'cp_dropdown_month_november',
  'cp_dropdown_month_december',
];

export const getMonths = () => monthKeys.map(translate);
