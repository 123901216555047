// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useRef, useState } from 'react';
import styles from './CreateNewRun.module.scss';

// 3RD PARTY
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

// OTHER COMPONENTS
import { ListItem } from 'ui/basic/menus/ListItem';
import {
  Button, HotChipsPeopleWithSearch, InputNext, TextEditor,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { getFullName } from 'utils/users';
import { useSelector } from 'react-redux';
import { selectAssessments } from 'store/selectors/assessment';
import { convertEditorContentToMailMD } from 'utils/textTools';
import { getUserImageUrl } from 'api';
import { apiNext } from 'apiNext';


// CONFIG & DATA
const Config = {
  SCOPE: {
    ORG: 'org',
    TEAMS: 'teams',
    USERS: 'users',
  },
};


// COMPONENT: CreateNewRun
const CreateNewRun = () => {
  const translate = useTranslate();
  const history = useHistory();
  const params = useParams();

  const assessmentsFromStore = useSelector(selectAssessments);
  const thisAssessment = assessmentsFromStore.find((el) => el.id === params.customAssessmentId);

  const [ disableButtons, setDisableButtons ] = useState(false);
  const [ selectedScope, setSelectedScope ] = useState();
  const [ description, setDescription ] = useState();
  const invitation = useRef();
  const [ additions, setAdditions ] = useState([]);

  const createNewRun = async () => {
    setDisableButtons(true);
    try {
      await apiNext.post(
        `core/assessments/${thisAssessment.id}/run`,
        {
          description: description || undefined,
          invitationText: convertEditorContentToMailMD(invitation.current),
          scope: selectedScope,
          [selectedScope]: selectedScope !== Config.SCOPE.ORG
            ? additions.map(({ value }) => value)
            : undefined,
        },
      );
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const mapSearchResults = useCallback((data) => {
    switch (selectedScope) {
      case Config.SCOPE.USERS: {
        return data.users.map((user) => ({
          img: getUserImageUrl(user.id),
          value: user.id,
          label: getFullName(user),
          subLabel: user.companyData.profession,
        }));
      }

      case Config.SCOPE.TEAMS: {
        return data.teams.map((team) => ({
          value: team.id,
          label: team.name,
          subLabel: team.type,
        }));
      }

      default:
        return [];
    }
  }, [ selectedScope ]);

  // RENDER: CreateNewRun
  return (
    <div className={styles.createNewRun}>
      { /* HEADER */ }
      <div className={styles.header}>
        <span>
          { translate('new_run_scope_header') }
        </span>
        <div
          className={styles.close}
          role='presentation'
          onClick={() => history.goBack()}
        >
          { translate('cancel_lbl') }
        </div>
      </div>

      { /* BODY */ }
      <div className={styles.scrollableContainer}>
        <div className={styles.gridContainer}>
          { !selectedScope ? (
            // 1st page: scope selection
            <div className={styles.gridContent}>
              <div className='bluTypeS'>
                { translate('new_run_scope_title', [
                  '{{assessment}}', thisAssessment?.title,
                ]) }
              </div>
              <div className='marginTopXs'>
                { translate('new_run_scope_descr') }
              </div>

              <div className={styles.menu}>
                { Object.values(Config.SCOPE).map((scope) => (
                  <ListItem
                    compactView
                    key={scope}
                    title={translate(`new_run_scope_${scope}_title`)}
                    description={translate(`new_run_scope_${scope}_descr`)}
                    onClick={() => setSelectedScope(scope)}
                  />
                )) }
              </div>
            </div>
          ) : (
            // 2nd page: details
            <div className={styles.gridContent}>
              { /* Introduction */ }
              <section>
                <div className='bluTypeS'>
                  { translate('new_run_config_title', [
                    '{{assessment}}', assessmentsFromStore.find((el) => el.id === params.customAssessmentId)?.title,
                  ]) }
                </div>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  { translate('new_run_config_descr') }
                </div>
              </section>

              { /* Write description */ }
              <section>
                <div className={styles.sectionTitle}>
                  { translate('new_run_config_descr_title') }
                </div>
                <div className={classNames('bluTypeCopy')}>
                  { translate('new_run_config_descr_copy') }
                </div>
                <div className={styles.sectionContent}>
                  <InputNext
                    label={translate('new_run_config_descr_placeholder')}
                    value={description}
                    onChange={setDescription}
                  />
                </div>
              </section>

              { /* Write invitation */ }
              <section>
                <div className={styles.sectionTitle}>
                  { translate('new_run_config_invite_title') }
                </div>
                <div className={classNames('bluTypeCopy')}>
                  { translate('new_run_config_invite_copy') }
                </div>
                <div className={styles.sectionContent}>
                  <TextEditor
                    placeholder={translate('new_run_config_invite_placeholder')}
                    onChange={(value) => { invitation.current = value; }}
                    content={invitation.current}
                  />
                </div>
              </section>

              { /* Add people */ }
              <section>
                <div className={styles.sectionTitle}>
                  { translate(`new_run_config_scope_${selectedScope}_title`) }
                </div>
                <div className={classNames('bluTypeCopy')}>
                  { translate(`new_run_config_scope_${selectedScope}_descr`) }
                </div>
                { selectedScope !== Config.SCOPE.ORG && (
                  <div className='marginTopXs'>
                    <HotChipsPeopleWithSearch
                      label={translate(`new_run_config_add_label_${selectedScope}`)}
                      placeholder={translate(`run_scope_add_placeholder_${selectedScope}`)}
                      hint={translate(`run_scope_add_hint_${selectedScope}`)}
                      noResultsHint={translate(selectedScope === Config.SCOPE.USERS
                        ? 'users_settings_filter_no_users_title'
                        : 'create_vacancy_team_no_results')}
                      values={additions}
                      onUpdate={setAdditions}
                      topFlyout
                      canMail={false}
                      searchConfig={{
                        entrypoint: selectedScope === Config.SCOPE.USERS
                          ? '/core/company/users/search'
                          : `/core/company/${selectedScope}`,
                        mapResults: mapSearchResults,
                        extraParams: { expand: 'user' },
                      }}
                    />
                  </div>
                ) }
              </section>

              { /* BUTTONS */ }
              <section className={styles.buttons}>
                <Button
                  size='S'
                  looks='secondary'
                  disabled={disableButtons}
                  onClick={() => {
                    setSelectedScope();
                    setAdditions([]);
                  }}
                >
                  { translate('back_lbl') }
                </Button>
                <Button
                  size='S'
                  disabled={disableButtons || (selectedScope !== Config.SCOPE.ORG && !additions.length)}
                  onClick={createNewRun}
                >
                  { translate('new_run_config_start') }
                </Button>
              </section>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
};

export default CreateNewRun;
