const REGEXES = {
  /* pattern based on but only match beginning but allow uppercase: https://regexr.com/3au3g */
  DOMAIN: /^(?:[A-z0-9](?:[A-z0-9-]{0,61}[A-z0-9])?\.)+[A-z0-9][A-z0-9-]{0,61}[A-z0-9]/,
  DOMAIN_FULL: /^(https?:\/\/)(?:[A-z0-9](?:[A-z0-9-]{0,61}[A-z0-9])?\.)+[A-z0-9][A-z0-9-]{0,61}[A-z0-9]/,
  SUBDOMAIN: /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+$/i,
  EMAIL: /^[\w-\\.\\+]+@([\w-]+\.)+[\w-]{2,}$/,
  DIGITS: /^[0-9]+$/,
  FLOATING_NUMBERS: /^\d*(?:[.,]?\d+)?$/,
};

export default REGEXES;
