// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './RunScope.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// API
import * as api from 'api';

// OTHER COMPONENTS
import {
  Modal,
  Pagination,
  ImgCircle,
  Checkbox,
  HotChipsPeopleWithSearch,
  SkeletonFlexible,
} from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { sortAlphabetically } from 'utils/strings';
import { getFullName } from 'utils/users';
import REGEXES from 'utils/configuration/const/regexes';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  PAGINATION: 5,
  SCOPE: {
    TEAMS: 'teams',
    USERS: 'users',
  },
};

// COMPONENT: RunScope
const RunScope = (props) => {
  // PROPS
  const {
    onClose,
    entity,
    runId,
    scope = Config.SCOPE.USERS,
    isActiveRun = true,
    onAdd,
    isSurvey = false,
  } = props;

  const urlBasePath = isSurvey ? 'surveys' : 'core/assessments';

  const [ pageIndex, setPageIndex ] = useState(0);
  const [ run, setRun ] = useState();
  const [ openParticipation, setOpenParticipation ] = useState();

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const searchCb = useCallback((data) => {
    switch (scope) {
      case Config.SCOPE.USERS: {
        const users = data.users.map((user) => {
          if (user.user) {
            user = user.user; // eslint-disable-line no-param-reassign
          }

          return {
            img: api.getUserImageUrl(user.id),
            value: user.id,
            label: getFullName(user),
            subLabel: user.companyData.profession,
          };
        });

        if (isSurvey && data.emails) {
          data.emails.forEach((el) => {
            users.push({
              value: el,
              label: el,
              subLabel: 'run_table_label_external',
            });
          });
        }

        return users;
      }

      case Config.SCOPE.TEAMS: {
        return data.teams.map((team) => ({
          value: team.id,
          label: team.name,
          subLabel: team.type,
        }));
      }

      default:
        return [];
    }
  }, [ scope, isSurvey ]);

  const [ list, setList ] = useState([]);
  const [ additions, setAdditions ] = useState([]);
  const [ adding, setAdding ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  /**
   * Add resources to assessment run scope and update view
   */
  const addResources = async () => {
    try {
      setAdding(true);
      const listValues = list.map(({ value }) => value);
      const filteredAdditions = additions
      .filter(({ value }) => !listValues.includes(value))
      .map((el) => ({ subLabel: 'run_table_label_external', ...el }));

      if (filteredAdditions.length || run.openParticipation !== openParticipation) {
        const values = filteredAdditions.map(({ value }) => value);

        let res;
        if (isSurvey) {
          res = await api.patch(
            `${urlBasePath}/${entity.id}/run`,
            {
              openParticipation,
              addEmails: values.filter((value) => REGEXES.EMAIL.test(value)),
              addUsers: values.filter((value) => !REGEXES.EMAIL.test(value)),
            },
          );
        } else {
          res = await api.post(
            `${urlBasePath}/${entity.id}/run/${runId}/resources`,
            { [scope]: values },
          );
        }

        if (!res?.ok) {
          throw new Error(res.data.error.errorMessage);
        }
      }

      setList((state) => [ ...state, ...filteredAdditions ]);
      setAdditions([]);
      onAdd();
      if (run.openParticipation !== openParticipation) {
        setRun((state) => ({ ...state, openParticipation }));
      }
    } catch (e) {
      console.error(e);
    } finally {
      setAdding(false);
    }
  };

  /**
   * Fetch run info
   */
  useEffect(() => {
    const getAssessmentRun = async () => {
      if (!entity.id || !runId || !scope) {
        return;
      }
      try {
        setLoading(true);
        const res = await api.get(`${urlBasePath}/${entity.id}/run/${runId}`, { expand: scope });
        setRun(res.data);
        setList(searchCb(res.data));
        setOpenParticipation(res.data.openParticipation);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    getAssessmentRun();
  }, [ entity.id, runId, scope, searchCb, urlBasePath ]);

  // RENDER: RunScope
  return (
    <Modal
      header={translate(`run_${isSurvey ? 'survey' : 'scope'}_title`)}
      primaryButtonTitle={isActiveRun ? translate('save') : null}
      primaryButtonDisabled={(run?.openParticipation === openParticipation && !additions.length) || adding}
      secondaryButtonTitle={translate('close_lbl')}
      onClose={onClose}
      onConfirm={addResources}
      actionOnEnter={false}
    >
      <div className={classNames(styles.runScope)}>
        <div className={styles.description}>
          { translate(
            `run_${isSurvey ? 'survey' : 'scope'}_description_${scope}_${isActiveRun ? 'active' : 'closed'}`,
            [
              '{{assessment}}', entity.title,
              '{{amount}}', list.length,
            ],
          ) }
        </div>
        <div className={styles.table}>
          { loading
            ? (
              <SkeletonFlexible noHeader repeat={5} />
            ) : (
              list
              .sort((a, b) => sortAlphabetically(a.label, b.label))
              .filter((_, index) => Math.floor(index / Config.PAGINATION) === pageIndex)
              .map((item) => {
                const isMail = REGEXES.EMAIL.test(item.value);
                return (
                  <div
                    key={`addition-${item.value}`}
                    className={classNames(styles.addition, { [styles.clickable]: !isMail })}
                    role='presentation'
                    onClick={() => {
                      if (isMail) {
                        return;
                      }
                      const entityType = scope === Config.SCOPE.USERS ? 'employees' : 'teams';
                      window.open(`/${entityType}/${item.value}/profile`, '_blank');
                    }}
                  >
                    { scope === Config.SCOPE.USERS && !isMail && (
                      <div className={styles.imgCircle}>
                        <ImgCircle
                          size='S'
                          src={api.getUserImageUrl(item.value)}
                          label1={item.label.split(' ')[0]}
                          label2={item.label.split(' ')[1]}
                        />
                      </div>
                    ) }
                    <div className={styles.text}>
                      <div className={styles.title}>
                        { item.label }
                      </div>
                      <div className={styles.subtitle}>
                        { translate(item.subLabel || '-') }
                      </div>
                    </div>
                  </div>
                );
              })) }
        </div>

        { (list.length / Config.PAGINATION) > 1 && (
          <Pagination
            pageCount={Math.ceil(list.length / Config.PAGINATION)}
            pagePosition={pageIndex}
            showPagerButtons
            showNumbers
            onPagePositionUpdate={setPageIndex}
          />
        ) }

        { isActiveRun && (
          <div className={styles.add}>
            <HotChipsPeopleWithSearch
              label={translate(`run_${isSurvey ? 'survey' : 'scope'}_add_label_${scope}`)}
              placeholder={translate(isSurvey
                ? 'fill_position__placeholder'
                : `run_scope_add_placeholder_${scope}`)}
              hint={translate(`run_${isSurvey ? 'survey' : 'scope'}_add_hint_${scope}`)}
              noResultsHint={translate(scope === Config.SCOPE.USERS
                ? 'users_settings_filter_no_users_title'
                : 'create_vacancy_team_no_results')}
              values={additions}
              onUpdate={(values = []) => {
                setAdditions(values);
                if (isSurvey && !values.length && !list.length) {
                  setOpenParticipation(true);
                }
              }}
              disabled={loading || adding}
              topFlyout
              canMail={isSurvey}
              searchConfig={{
                entrypoint: scope === Config.SCOPE.USERS
                  ? '/core/company/users/search'
                  : '/core/company/teams',
                mapResults: searchCb,
                extraParams: { expand: 'user' },
              }}
            />
          </div>
        ) }

        { isSurvey && isActiveRun && (
          <div className={styles.openLink}>
            { translate('run_share_copy') }
            <Checkbox
              name={translate('run_share_label')}
              checked={openParticipation}
              disabled={loading || adding || (!list.length && !additions.length)}
              onChange={setOpenParticipation}
            />
          </div>
        ) }
      </div>
    </Modal>
  );
};

export default RunScope;
