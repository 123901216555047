import React, { useEffect, useRef, useState } from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';


const TextArea = (props) => {
  const {
    value,
    label,
    placeholder,
    hint = '',
    hintRight = '',
    disabled = false,
    maxLength,
    onChange,
  } = props;

  const [ smallLabel, setSmallLabel ] = useState(false);
  const [ valueInternal, setValueInternal ] = useState(value || '');
  const componentId = useRef(uuid());

  useEffect(() => {
    setValueInternal(value);
    setSmallLabel(Boolean(value));
  }, [ value ]);

  return (
    <div className={styles.textarea}>
      { /* LABEL */ }
      { label && !placeholder && (
        <label
          htmlFor={componentId.current}
          className={classNames(
            styles.label,
            { [styles.small]: smallLabel },
            { [styles.disabled]: disabled },
          )}
        >
          { label }
        </label>
      ) }

      { /* TEXTAREA */ }
      <textarea
        id={componentId.current}
        placeholder={placeholder}
        className={classNames({
          [styles.hasHintRight]: Boolean(hintRight),
        })}
        disabled={disabled}
        maxLength={maxLength}
        value={valueInternal}
        onChange={(event) => {
          setValueInternal(event.target.value);
          onChange?.(event.target.value);
        }}
        onFocus={() => {
          setSmallLabel(true);
        }}
        onBlur={() => {
          setSmallLabel(Boolean(valueInternal));
        }}
      />

      { /* HINTS */ }
      { hintRight && (
        <div className={styles.hintRight}>
          { hintRight }
        </div>
      ) }
      { hint && (
        <div className={styles.hint}>
          { hint }
        </div>
      ) }
    </div>
  );
};

export default TextArea;
