// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './IstRegistration.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import dayjs from 'dayjs';

// OTHER COMPONENTS
import { DropDown, InputMasked, InputNumber } from 'ui/basic';
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator/translator';
import { isTimestampInFuture } from 'utils/dateTools';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// COMPONENT: IstRegistration
const IstRegistration = (props) => {
  // PROPS
  const {
    assessmentDetails = {},
    skipRegistration = false,
    extras = {},
    onClickNext = () => {},
  } = props;

  const { loadingDuring, setLoadingDuring } = extras;
  const { registrationSchema = {} } = assessmentDetails;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ gender, setGender ] = useState();
  const [ weight, setWeight ] = useState();
  const [ weightIsValid, setWeightIsValid ] = useState();

  const [ height, setHeight ] = useState();
  const [ heightIsValid, setHeightIsValid ] = useState();

  const [ abdGirth, setAbdGirth ] = useState();
  const [ abdGirthIsValid, setAbdGirthIsValid ] = useState();
  const [ activity, setActivity ] = useState();

  const dateFormat = dayjs().localeData().longDateFormat('L');

  const [ dateOfBirth, setDateOfBirth ] = useState();
  const [ dateOfBirthTimeStamp, setDateOfBirthTimeStamp ] = useState();
  const [ dateOfBirthErrorMessage, setDateOfBirthErrorMessage ] = useState('');
  const [ isBlurred, setIsBlurred ] = useState(true);

  const handleDateOfBirthChange = (value) => {
    const date = dayjs(value, dateFormat, true);
    const dateTimestamp = date.unix();

    let error = '';
    if (!date.isValid()) {
      error = translate('date_full_invalid_format_msg', [ '{{format}}', dateFormat ]);
    } else if (isTimestampInFuture(dateTimestamp)) {
      error = translate('date_in_future_error_msg');
    }

    setDateOfBirthErrorMessage(value ? error : '');
    setDateOfBirth(value);
    setDateOfBirthTimeStamp(dateTimestamp);
  };

  const valuesAreValid = dateOfBirth && !dateOfBirthErrorMessage
    && gender
    && weight && weightIsValid
    && height && heightIsValid
    && (!abdGirth || (abdGirth && abdGirthIsValid));

  const [ errorMessage, setErrorMessage ] = useState('');
  const handleRegister = () => {
    if (!valuesAreValid) {
      return;
    }

    setLoadingDuring(true);

    const payload = {
      gender: gender.value,
      date_of_birth: dateOfBirthTimeStamp,
      weight: Number(weight),
      height: Number(height),
      abdominal_girth: abdGirth ? Number(abdGirth) : undefined,
      activity: activity?.value,
    };

    api.post('/core/assessments/ist/register', payload)
    .then(({ ok, data = {} }) => {
      if (ok) {
        onClickNext();
        return;
      }
      setErrorMessage(data.error?.errorMessage);
    })
    .catch((error) => {
      setErrorMessage(error?.message);
    })
    .finally(() => {
      setLoadingDuring(false);
    });
  };

  if (skipRegistration) {
    onClickNext();
    return null;
  }

  // RENDER: IstRegistration
  return (
    <AssessmentPageWithFooter
      nextButtonText={translate('continue_lbl')}
      onClickNext={(valuesAreValid && !loadingDuring)
        ? handleRegister
        : undefined}
    >
      <div className={classNames(styles.istRegistration)}>
        <span className='bluTypeXxs'>
          { translate('ist_report_personal_data_title') }
        </span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('ist_reg_copy') }
        </div>

        { errorMessage && (
          <div className={styles.errorMessage}>{ errorMessage }</div>
        ) }

        <div className={styles.personalInfo}>
          <div className='bluTypeLabelL'>{ translate('personal_info') }</div>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('ist_reg_personal_info_copy') }
          </div>
          <div className={classNames(styles.inputs, 'marginTopS')}>
            <DropDown
              placeholder={translate('rmp_ass_form_gender_placeholder')}
              options={registrationSchema.gender?.options}
              optionLabel='name'
              optionValue='value'
              onChange={(option) => setGender(option)}
            />

            <InputMasked
              label={`${translate('date_of_birth')}*`}
              mask={dateFormat.replaceAll(/\w/g, '9')}
              placeholder={!isBlurred ? dateFormat : undefined}
              errorMessage={dateOfBirthErrorMessage}
              value={dateOfBirth}
              onFocus={() => setIsBlurred(false)}
              onBlur={() => setIsBlurred(true)}
              onChange={handleDateOfBirthChange}
            />
          </div>
        </div>

        <div className={styles.physioData}>
          <span className='bluTypeLabelL'>{ translate('physio_data') }</span>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('ist_reg_physio_data_copy') }
          </div>

          <div className={classNames(styles.inputs, 'marginTopS')}>
            <InputNumber
              label={`${translate('ist_reg_weight')}*`}
              floatingNumbers
              onChange={(value, valueIsValid) => {
                setWeight(value);
                setWeightIsValid(valueIsValid);
              }}
            />
            <InputNumber
              label={`${translate('ist_reg_height')}*`}
              onChange={(value, valueIsValid) => {
                setHeight(value);
                setHeightIsValid(valueIsValid);
              }}
            />
          </div>

          <div className={classNames(styles.inputs, 'marginTopXs')}>
            <InputNumber
              label={translate('ist_reg_abdominal_girth')}
              onChange={(value, valueIsValid) => {
                setAbdGirth(value);
                setAbdGirthIsValid(valueIsValid);
              }}
            />

            <DropDown
              placeholder={translate('activity')}
              options={registrationSchema.activity?.options}
              optionLabel='name'
              optionValue='value'
              onChange={(option) => setActivity(option)}
            />
          </div>

          <div className={classNames('bluTypeCopy', 'marginTopM')}>
            { translate('rmp_ass_form_hint_required_info') }
          </div>
        </div>
      </div>
    </AssessmentPageWithFooter>
  );
};

export default IstRegistration;
