import React, { useEffect, useState } from 'react';
import styles from './Peer360AssessmentEnd.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { getAssessmentResult } from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { selectIsCoachHub } from 'store/selectors/configuration';

// OTHER COMPONENTS
import { Button, LoadingHint, Loading } from 'ui/basic';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import {
  getIsExternalSystemIntegrationSupported,
  replaceCoachHubTarget,
} from 'features/+coachHub/utils/localStorage';
import { returnToUrl } from 'features/+coachHub/utils/returnUrl';

// CONFIG & DATA


const Peer360AssessmentEnd = (props) => {
  const {
    userId,
    peerFor = '',
    hasBqAccount,
    finishable,
    handleFinish = () => {},
    errorEnd,
    managerControlled,
    asPeer,
    customContent = {},
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const companySettings = useSelector(fromSettingsSelectors.getCompanySettings);
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  const isCoachHub = useSelector(selectIsCoachHub);
  const externalSystemSupported = getIsExternalSystemIntegrationSupported();
  const loggedAsUser = me.id;

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  const [ assessmentFetchProcessing, setAssessmentFetchProcessing ] = useState(false);
  const [ assessmentCompletedForAllPeers, setAssessmentCompletedForAllPeers ] = useState();
  useEffect(() => {
    // list of assessments should be re-fetched only for 'self' user, not for the peers
    if (!finishable || peerFor) {
      return;
    }

    setAssessmentFetchProcessing(true);
    apiNext.get(`/core/assessments/${params.customAssessmentId}`)
    .then((response = {}) => {
      const { peerProgress } = response;
      if (peerProgress) {
        const completedForAllPeers = Object.values(peerProgress).every(({ progress }) => progress >= 100);
        setAssessmentCompletedForAllPeers(completedForAllPeers);
      }
    })
    .catch((error) => {
      console.error(error.message);
    })
    .finally(() => {
      setAssessmentFetchProcessing(false);
    });
  }, [
    params.customAssessmentId,
    finishable,
    peerFor,
    isCoachHub,
  ]);

  const getPeerButtonTitle = () => {
    if (externalSystemSupported || loggedAsUser) {
      return translate('close_lbl');
    }

    if (userId === localStorage.getItem('mail')) {
      return translate('rmp_ass_success_button_view_profile');
    }

    const noBqAccountButtonLabel = companySettings.linkoutButtonLabel
      ? translate(companySettings.linkoutButtonLabel)
      : translate('bq_learn_more');

    return hasBqAccount
      ? translate('share_profile_link_expired_btn')
      : noBqAccountButtonLabel;
  };

  const handleButtonClick = () => {
    if (externalSystemSupported) {
      window.close();
      return;
    }

    if (loggedAsUser || hasBqAccount || userId === localStorage.getItem('mail')) {
      history.push('/');
    } else {
      const link = companySettings.linkoutButtonUrl || 'https://bluquist.com/';
      window.open(link, '_self');
    }
  };

  const handleReportButtonClick = () => {
    if (assessmentCompletedForAllPeers) {
      dispatch(getAssessmentResult(params.customAssessmentId));
      if (managerControlled) {
        handleFinish(() => history.push('/'));
        return;
      }

      if (externalSystemSupported) {
        replaceCoachHubTarget('profile', '/my-profile');
      } else {
        handleFinish(() => (
          history.push(`/my-profile/strength-profile?route=${params.customAssessmentId}&openReport=1`)
        ));
      }

      return;
    }

    if (asPeer) {
      handleFinish(() => history.push('/'));
      return;
    }

    if (externalSystemSupported) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'assessment_completed',
          assessment: params.customAssessmentId,
          results_available: false,
        },
      });
    } else {
      handleFinish(() => history.push(`/assessments/${params.customAssessmentId}/peer/settings`));
    }
  };

  const getClosingLabel = () => {
    if (asPeer) {
      return 'close_lbl';
    }
    if (!assessmentCompletedForAllPeers) {
      if (externalSystemSupported) {
        return 'close_lbl';
      }
      return 'peer_360_goto_assmnt_mng_page';
    }
    if (managerControlled) {
      return 'close_lbl';
    }
    return 'assessment_show_report';
  };

  // this is the case only for 'self' users
  if (externalSystemSupported && !peerFor && assessmentCompletedForAllPeers === undefined) {
    return <Loading visible />;
  }

  return (
    <div
      className={styles.peer360AssessmentEnd}
      data-test='AssessmentEnd'
    >
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { !errorEnd && (
        <>
          <span className={styles.graphic} role='img' aria-label='applause'>
            &#128588;
          </span>

          <div className='bluTypeS'>
            { translate((
              peerFor ? customContent.peer_completed_success_title : customContent.self_completed_success_title)
              ?? 'self_completed_success_title') }
          </div>

          { /* section for peer users */ }
          { peerFor && (
            <>
              <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                { translate(
                  customContent.peer_360_ass_success_title ?? 'peer_360_ass_success_title',
                  [ '{{username}}', peerFor ],
                ) }
              </div>

              { (!externalSystemSupported || window.history?.length === 1) && (
                <Button
                  size='M'
                  looks='secondary'
                  disabled={!finishable}
                  onClick={handleButtonClick}
                >
                  { getPeerButtonTitle() }
                </Button>
              ) }
            </>
          ) }

          { /* section for 'self' user */ }
          { !peerFor && (
            <>
              { assessmentFetchProcessing && (
                <div className={styles.loading}>
                  <LoadingHint />
                </div>
              ) }

              { !assessmentFetchProcessing && (
                <>
                  { assessmentCompletedForAllPeers === false && (
                    <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                      { translate(customContent.self_partially_completed_copy
                        ?? 'peer_360_assmnt_partially_completed') }
                    </div>
                  ) }

                  <Button
                    size='M'
                    looks='secondary'
                    disabled={!finishable}
                    onClick={handleReportButtonClick}
                  >
                    { translate(getClosingLabel()) }
                  </Button>
                </>
              ) }
            </>
          ) }
        </>
      ) }
    </div>
  );
};

export default Peer360AssessmentEnd;
