// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './QuestionInCompanySince.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import dayjs from 'dayjs';

// OTHER COMPONENTS
import { InputMasked } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { isTimestampInFuture } from 'utils/dateTools';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: QuestionInCompanySince
const QuestionInCompanySince = (props) => {
  // PROPS
  const {
    answer = {},
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ errorMessage, setErrorMessage ] = useState();
  const [ isBlurred, setIsBlurred ] = useState(true);

  const [ inCompanySince, setInCompanySince ] = useState('');
  useEffect(() => {
    setInCompanySince(answer.inCompanySince);
  }, [ answer.inCompanySince ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const dateFormat = new Intl.DateTimeFormat(
    dayjs().locale(),
    { year: 'numeric', month: '2-digit' },
  )
  .format(new Date())
  .replace(/\b\d{4}\b/, 'YYYY')
  .replace(/\b\d{2}\b/, 'MM');
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: QuestionGender
  return (
    <div className={classNames(styles.questionInCompanySince)}>

      { /* FORM */ }
      <div className={classNames(styles.formRow)}>
        <InputMasked
          label={translate('cp_subheadline_timeincomp')}
          mask={dateFormat.replaceAll(/\w/g, '9')}
          value={inCompanySince}
          errorMessage={isBlurred ? errorMessage : undefined}
          placeholder={!isBlurred ? dateFormat : undefined}
          onFocus={() => setIsBlurred(false)}
          onBlur={() => setIsBlurred(true)}
          onChange={(value) => {
            // validation
            let thisErrorMessage = '';
            const date = dayjs(value, dateFormat, true).add(12, 'hours');
            const dateTimestamp = date.unix();
            if (Number.isNaN(dateTimestamp)) {
              thisErrorMessage = translate(
                'date_invalid_error_msg_v2',
                [ '{{format}}', dateFormat ],
              );
            } else if (isTimestampInFuture(dateTimestamp)) {
              thisErrorMessage = translate('date_in_future_error_msg');
            } else if (date.year() < 1970) {
              thisErrorMessage = translate('date_old_error_msg');
            }

            setErrorMessage(thisErrorMessage);
            setInCompanySince(value);
            onAnswer({
              inCompanySince: value,
              inCompanySinceTimestamp: dateTimestamp,
              isValid: !thisErrorMessage,
            });
          }}
        />
      </div>

    </div>
  );
};

export default QuestionInCompanySince;
