// 3RD PARTY
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// UTILS
import { getMonths } from './months';
import { DATE_FORMAT } from './configuration/const/languages';


// from Lokalise languages list
export const SUPPORTED_MOMENT_LOCALES = [
  'de', 'zh', 'nl', 'en', 'fr',
  'hu', 'it', 'ja', 'ko', 'pl',
  'pt', 'ro', 'ru', 'es', 'tr',
  'uk',
];

// Load dayjs plugins
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(weekOfYear);


export const setDateLanguage = async (lang) => {
  let browserLocale = lang ?? navigator.languages?.[0] ?? navigator.language ?? '';
  const [ localePrefix ] = browserLocale.split('-');
  if (!SUPPORTED_MOMENT_LOCALES.includes(localePrefix)) {
    browserLocale = 'en-gb'; // default is UK English, to avoid the wacky american date format
  }

  // Load locale
  try {
    await import(`dayjs/locale/${browserLocale.toLowerCase()}.js`);
  } catch (e) {
    if (e.code === 'MODULE_NOT_FOUND') {
      await import(`dayjs/locale/${localePrefix}.js`);
    }
  }
  dayjs.locale(browserLocale);
};

export const timestampToDate = (timestamp) => new Date(timestamp * 1000); //* 1000 to get milliseconds

export const timestampToFullDate = (timestamp, format = 'L') => {
  if (!timestamp) {
    return '';
  }
  return dayjs(timestampToDate(timestamp)).format(format);
};

export const timestampToDateString = (timestamp, language = 'en') => {
  const date = timestampToDate(timestamp);
  let month;
  if (language === 'en') {
    month = ` ${getMonths()[date.getMonth()]}`;
  } else {
    month = `. ${getMonths()[date.getMonth()]}`;
  }

  return `${date.getDate()}${month} ${date.getFullYear()}`;
};

export const isTimestampInPast = (timestamp) => {
  const validUntilTime = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
  const todayTime = new Date().setHours(0, 0, 0, 0);

  return validUntilTime < todayTime;
};

export const millisecondsToDays = (ms) => Math.ceil(ms / 1000 / 60 / 60 / 24);
export const isTimestampInFuture = (timestamp) => {
  const enteredTime = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
  const todayTime = new Date().setHours(0, 0, 0, 0);

  return enteredTime > todayTime;
};

// date string format 'DD-MM-YYYY HH:mm:ss'
export const convertDateToLocale = (dateString = '') => {
  const dateRgx = /\d{4}-\d{2}-\d{2}/;
  const timeRgx = /\d{2}:\d{2}:\d{2}/;
  const [ date ] = dateString.match(dateRgx);
  const [ time ] = dateString.match(timeRgx);
  if (!date || !time) {
    throw new Error('convertDateToLocale: invalid date format');
  }

  return dateString.replace(
    new RegExp(`${dateRgx.source} ${timeRgx.source}`),
    new Date(`${date}T${time}Z`).toLocaleString(),
  );
};

// returns the parsed time if the provided string is in ISO8601 format, undefined otherwise
// https://en.wikipedia.org/wiki/ISO_8601
export const tryParseTimeFromIso8601 = (timeString) => {
  const time = dayjs(timeString);
  return time.isValid() ? time : undefined;
};

// Attempt to convert DD.MM.YYYY date to locale format.
// Used by candidate components, bc the start date is stored on db in DE format.
export const convertCandidateStartDateToLocale = (date) => {
  const localeDate = dayjs(date, DATE_FORMAT.DE, true);
  if (localeDate.isValid()) {
    return localeDate.format('L');
  }
  return date;
};
